<template>
  <div>
    <b-card>
      <b-card>
        <div class="row mb-2">
          <div class="col">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="bugunkuListe !== true ? 'success' : 'primary'"
                class="btn-icon mr-1"
                @click="changeListItem(true)"
            >
              Tüm Kayıtları Göster
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="bugunkuListe === true ? 'success' : 'primary'"
                class="btn-icon mr-1"
                @click="changeListItem(false)"
            >
              Bugünkü Kayıtları Göster
            </b-button>
          </div>
          <div class="col" style="text-align: right;">
            <b-form-group
                label="Ara: "
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
                label-for="filterInput"
                class="mb-0"
            >
              <b-input-group size="md">
                <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder=""
                />
                <b-input-group-append>
                  <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                  >
                    Temizle
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </div>

        <b-table
            :key="sayi"
            :striped=true
            :hover=true
            :foot-clone=true
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            class="mb-0"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
        >
          <template #cell(number)="data">
            <div class="ml-1">
              {{ data.value }}
            </div>
          </template>
          <template #cell(cargo)="data">
            <b-badge
                pill
                :variant="data.value === 'Verildi' ? 'success' : 'danger'"
            >
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(id)="data">
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Düzenle'"
                variant="info"
                class="btn-icon mr-1"
                @click="sendOrderEdit(data.item)"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Detay görüntüle'"
                v-b-modal.OrderModalDetail
                variant="success"
                class="btn-icon mr-1"
                @click="orderDetail(data.item.orderGroup)"
            >
              <feather-icon icon="EyeIcon"/>
            </b-button>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="'Kargo Kodu Düzenle'"
                v-b-modal.EditCargoCodeModal
                variant="info"
                class="btn-icon mr-1"
                @click="sendCargoClick(data.item.orderGroup, data.item.cargoCode)"
            >
              <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
                v-if="data.item.cargo !== 'Verildi'"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="'Kargoya ver'"
                v-b-modal.SendCargoModal
                variant="primary"
                class="btn-icon"
                @click="sendCargoClick(data.item.orderGroup, data.item.cargoCode)"
            >
              <feather-icon icon="BoxIcon"/>
            </b-button>
            <b-button
                v-if="isAdmin"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-tooltip.hover.top="'Siparişi Sil'"
                variant="danger"
                class="btn-icon ml-1"
                @click="deleteOrder(data.item.orderGroup)"
            >
              <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>
        </b-table>
        <div class="mx-2 mb-2 mt-2">
          <b-row>
            <!-- Per Page -->
            <b-col
                cols="6"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <b-form-select
                  v-model="perPage"
                  :options="[10,20,30,40,50]"
                  class="per-page-selector d-inline-block col-sm-2"
              />
              <label class="ml-1">Kayıt göster</label>
            </b-col>

            <!-- Pagination -->
            <b-col
                cols="6"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >

              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRow"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

            <b-col
                cols="12"
                sm="12"
                class="mt-2 d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Toplam {{ totalRow }} kayıt bulunmaktadır.</span>
            </b-col>

          </b-row>
        </div>
      </b-card>

      <b-modal
          id="OrderModalDetail"
          centered
          no-close-on-backdrop
          title="Sipariş Detayı"
          hide-footer
          @hide="onModalHide"
      >
        <div class="container">
          <div class="row">
            <div v-for="product in selectedOrderDetail" :key="product.productId" class="col-md-6 mt-3">
              <b-card>
                <h5 class="card-title"><strong>Ürün Adı:</strong> {{ product.productName }}</h5>
                <p class="card-text"><strong>Ürün Kodu:</strong> {{ product.code }}</p>
                <p class="card-text"><strong>Renk:</strong> {{ product.description }}</p>
                <p class="card-text"><strong>Adet:</strong> {{ product.quantity }}</p>
                <p class="card-text"><strong>Kargoda?:</strong> {{ product.cargo ? 'Evet' : 'Hayır' }}</p>
              </b-card>
            </div>
          </div>
        </div>
      </b-modal>

      <b-modal
          id="EditCargoCodeModal"
          hide-footer
          centered
          ref="editCargoModal"
          title="Kargo Kodu Düzenle"
      >
        <b-form-input
            placeholder="Kargo kodu"
            v-model="cargoCode"
            class="mb-2"
        />
        <div style="text-align: right;">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              @click="orderEditCargo(selectedCargoOrderGroup)"
          >
            Düzenle
          </b-button>
        </div>
      </b-modal>

      <b-modal
          id="SendCargoModal"
          hide-footer
          centered
          ref="sendCargoModal"
          title="Kargoya Ver"
      >
        <div class="row mx-1 my-2 mb-3">
          <h4>Kargoya vermek istediğinizden emin misiniz?</h4><br/>
          <span style="font-size: 12px;">(Bu işlem geri alınamaz!)</span>
        </div>

        <div class="row mb-2" style="text-align: center;">
          <div class="col">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                block
                class="btn-icon"
                @click="orderSendCargo(selectedCargoOrderGroup)"
            >
              Evet
            </b-button>
          </div>
          <div class="col">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                block
                class="btn-icon"
                @click="sendCargoModalHide"
            >
              Hayır
            </b-button>
          </div>
        </div>

      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BSpinner,
  BButton,
  VBTooltip,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BModal,
  BPagination,
  BRow,
  VBModal,
  BTable
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import 'animate.css'
import axios from "axios";
import {mapGetters} from "vuex";
import {all} from "core-js/internals/document-all";

export default {
  components: {
    BForm,
    BSpinner,
    BTable,
    BCard,
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  }, data() {
    return {
      perPage: 10,
      totalRow: 0,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'number',
          label: 'Sıra',
        },
        {
          key: 'cargoCode',
          label: 'Kargo Kodu',
        },
        {
          key: 'customerId',
          label: 'Müşteri Adı',
        },
        {
          key: 'orderType',
          label: 'Ödeme Türü',
        },
        {
          key: 'typePrice',
          label: 'Tutar',
        },
        {
          key: 'cargo',
          label: 'Kargoya verildi mi?',
        },
        {
          key: 'createdAt',
          label: 'Sipariş Tarihi',
        },
        {
          key: 'id',
          label: 'İşlemler',
        }
      ],
      items: [],
      customerList: [],
      selectedOrderDetail: null,
      selectedCargoOrderGroup: null,
      cargoCode: "",
      todayListItems: [],
      allListItems: [],
      sayi: 0,
      bugunkuListe: true,
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: "token/getIsAdmin"
    })
  },
  mounted() {
  },
  created() {
    axios.get('/customer/list')
        .then(response => {
          this.customerList = response.data.data;

          this.siparisListele();
        })
        .catch();
  },
  methods: {
    sendCargoClick(orderGroup, cargoCode) {
      this.cargoCode = cargoCode
      this.selectedCargoOrderGroup = orderGroup
    },
    siparisListele: function () {
      axios.get('/order/list')
          .then(resp => {
            if (resp.data.data !== null && resp.data.data.length > 0) {
              let i = 1;
              this.items = []
              this.todayListItems = []
              this.allListItems = []
              resp.data.data.forEach(r => {
                const customer = this.customerList.find(c => c.id === r.customerId);
                const customerNameSurname = customer ? customer.nameSurname : "Bilinmeyen Müşteri";

                let value = {
                  number: i,
                  customerIdd: customer ? customer.id : 0,
                  cargoCode: r.cargoCode,
                  orderGroup: r.orderGroup,
                  orderType: r.orderType === 0 ? "-" : r.orderType === 1 ? "Eft" : r.orderType === 2 ? "Kapıda" : "",
                  typePrice: r.typePrice,
                  customerId: customerNameSurname,
                  cargo: r.cargo ? "Verildi" : "Verilmedi",
                  createdAt: new Date(r.createdAt).toLocaleDateString('tr-TR') + ' ' + new Date(r.createdAt).toLocaleTimeString('tr-TR', {
                    hour: 'numeric',
                    minute: 'numeric'
                  }),
                }

                if (new Date().toLocaleDateString('tr-TR') === new Date(r.createdAt).toLocaleDateString('tr-TR')) {
                  this.todayListItems.push(value)
                }

                this.allListItems.push(value);

                i++;
              });
              this.items = this.todayListItems;
              this.totalRow = this.todayListItems.length;
            }
          })
          .catch();
    },
    orderDetail(orderGroup) {
      axios.post('/order/detail', {
        orderGroup: orderGroup
      })
          .then(response => {
            this.selectedOrderDetail = response.data.data
          })
          .catch()
    },
    sendOrderEdit(selectedOrder) {
      this.$store.dispatch('token/changeOrderSelect', selectedOrder)
      this.$router.push('/orderEdit')
    },
    deleteOrder(orderGroup) {
      this.$swalDeleteAsk().then(result => {
        if(result){
          axios.delete('/order/delete', {
            params: {
              orderGroup: orderGroup
            }
          })
              .then(response => {
                this.$toastApiResponse(response.data)

                let index1 = this.allListItems.findIndex(r => r.orderGroup === orderGroup)
                if(index1 !== -1)
                  this.allListItems.splice(index1, 1)

                let index2 = this.todayListItems.findIndex(r => r.orderGroup === orderGroup)
                if(index2 !== -1)
                  this.todayListItems.splice(index2, 1)

                this.bugunkuListe = true
              })
        }
      })
    },
    onModalHide() {
      setTimeout(() => {
        this.selectedOrderDetail = null
      }, 500)
    },
    sendCargoModalHide() {
      this.$refs.sendCargoModal.hide()
    },
    changeListItem(allItems) {
      if (allItems) {
        this.items = this.allListItems;
        this.totalRow = this.allListItems.length;
        this.bugunkuListe = false;
      } else {
        this.items = this.todayListItems;
        this.totalRow = this.todayListItems.length;
        this.bugunkuListe = true;
      }
      this.sayi++
    },
    orderEditCargo(orderGroup) {
      this.$refs.editCargoModal.hide()
      axios.post('/order/editcargocode', {
        cargoCode: this.cargoCode,
        orderGroup: orderGroup
      })
          .then(response => {
            this.$toastApiResponse(response.data)

            try {
              this.allListItems.find(r => r.orderGroup === orderGroup).cargoCode = this.cargoCode
              this.todayListItems.find(r => r.orderGroup === orderGroup).cargoCode = this.cargoCode
            } catch (err) { }
          })
          .catch()
    },
    orderSendCargo(orderGroup) {
      this.$refs.sendCargoModal.hide()
      axios.post('/order/sendcargo', {
        orderGroup: orderGroup
      })
          .then(response => {
            this.$toastApiResponse(response.data)

            try {
              this.allListItems.find(r => r.orderGroup === orderGroup).cargo = "Verildi"
              this.todayListItems.find(r => r.orderGroup === orderGroup).cargo = "Verildi"
            } catch (err) { }
          })
          .catch()
    },
    onFiltered(filteredItems) {
      this.totalRow = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style>

</style>
